import useTranslation from '@hooks/useTranslation';
import { PageLayout } from '@layout';

const Home = () => {
    const { t } = useTranslation('404');
    return (
        <PageLayout>
            <p
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 400,
                }}
            >
                {t('message')}
            </p>
        </PageLayout>
    );
};

export default Home;
